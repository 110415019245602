<template>
  <el-container>
    <el-aside
      class="home-aside"
      width="260px"
      v-if="$route.path !== '/edit' && $route.path !== '/articleDetail'"
    >
      <el-menu
        :router="true"
        :default-active="currentIndex"
        @select="handleMenuSelect"
      >
        <el-menu-item
          v-for="(item, index) in menus"
          :index="'' + (index + 1)"
          :key="index"
          :route="item.route"
          :ref="item.ref"
          style="padding-left: 0px"
        >
          <template slot="title">
            <div style="padding: 0 10px">
              <i :class="item.icon"></i>{{ item.title }}
            </div>
          </template>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-main class="home-content">
      <keep-alive include="record">
        <router-view></router-view>
      </keep-alive>
    </el-main>
  </el-container>
</template>
<script>
import { getUserType } from "../utils/store";
export default {
  name: "index",

  data() {
    return {
      currentIndex: "1",
      user_type: getUserType(),
      studentMenus: [
        {
          selected: true,
          title: "Home",
          icon: "",
          route: "/home",
          ref: "home",
        },
        {
          selected: false,
          title: "Draft",
          icon: "",
          route: "/draft",
          ref: "draft",
        },
        {
          selected: false,
          title: "Article",
          icon: "",
          route: "/article",
          ref: "article",
        },
      ],
      adminMenus: [
        {
          selected: true,
          title: "Submission",
          icon: "",
          route: "/submission",
          ref: "submission",
        },
        {
          selected: false,
          title: "Scoring & Awards",
          icon: "",
          route: "/scoring",
          ref: "scoring",
        },
        {
          selected: false,
          title: "Review Cycle",
          icon: "",
          route: "/review",
          ref: "review",
        },
        {
          selected: false,
          title: "Sections",
          icon: "",
          route: "/sections",
          ref: "sections",
        },
      ],
    };
  },
  computed: {
    menus() {
      return this.user_type === "student" ? this.studentMenus : this.adminMenus;
    },
  },

  watch: {
    $route: {
      handler(val, old) {
        const idx = this.menus.findIndex((item) => item.route === val.path);
        this.handleMenuSelect(String(idx + 1));
      },
      deep: true,
    },
  },

  mounted() {
    const idx = this.menus.findIndex((item) => item.route === this.$route.path);
    this.handleMenuSelect(String(idx + 1));
  },

  methods: {
    handleMenuSelect(index) {
      if (this.currentIndex == index) {
        return;
      }
      for (let i = 0; i < this.menus.length - 1; i++) {
        this.menus[i].selected = false;
      }
      this.currentIndex = index;
      this.menus[index - 1].selected = true;
      const curRef = this.menus[index - 1].ref;
      // 这里本身是单层菜单，如果是多层菜单要改写
      this.$refs[curRef][0].$el.focus();
    },
  },
};
</script>

<style scoped lang="scss">
.el-container {
  padding: 20px 40px;
  background-color: #f2f4f9;
}

.home-content {
  height: calc(100vh - 90px);
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
}

.home-aside {
  overflow-y: auto;
  margin-right: 20px;
  height: fit-content;
  background: transparent;
  //box-shadow: 2px 0px 6px 1px rgba(0, 21, 41, 0.12);
  //padding: 20px 0;
  /deep/ .el-menu {
    //height: calc(100vh - 50px);
    border-right: none;
  }

  .upload-button {
    width: 100%;
    margin-top: 20px;
    text-align: left;
  }
}

/deep/ .el-menu-item {
  padding: 0px 0px;
  &.is-active {
    background-color: #fdf1f0;
  }
}
.el-menu {
  background: white;
}
.menu-item {
  box-sizing: border-box;
  padding-left: 20px;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  color: #c9c9c9;
  font-size: 16px;
  font-family: GravityBold;
  background-color: #fff;
  border-top: 1px solid white;

  //&:hover {
  //  color: white;
  //  background-color: var(--c-primary);
  //}
}

.menu-item-selected {
  color: var(--c-primary);
  background-color: white;
}
</style>
